<template>
  <div class="merchant-container">
    <div style="height: 50px">
      <div style="float: left; margin: 5px 0 0 20px">
        <el-button icon="el-icon-plus" type="warning" @click="handleEdit" size="small">新增</el-button>
        <!--        <el-button @click="rechargePackageSettings" type="danger" size="small">充值套餐设置</el-button>-->
        <el-button @click="rechargePackageSettings" type="info" size="small">查看奖励历史</el-button>
        <span style="margin-left: 20px">模块开关:</span>
        <el-radio-group v-model="form.type" size="small" @change="setTopPackage">
          <el-radio-button label="0">暂不启用</el-radio-button>
          <el-radio-button label="1">启用</el-radio-button>
        </el-radio-group>
      </div>
      <div style="float: right">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="套餐名称:">
            <el-input
                v-model.trim="queryForm.name"
                clearable
                placeholder="套餐名称"
                size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          align="center"
          label="套餐图片"
          prop="imgUrl"
      >
        <template #default="{row}">
          <el-image v-if="row.icon" :src="row.icon" class="commodity_pic"/>
          <span v-else>暂无图片</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="套餐名称">
      </el-table-column>
      <el-table-column
          align="center"
          prop="type"
          label="充值类型">
        <template #default="{ row }">
          <span v-if="row.type == 1">自定义套餐</span>
          <span v-else>定额套餐</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="cash"
          label="现金面值/最小价格"
      >
        <template #default="{ row }">
          <span v-if="row.cash">{{ row.cash }}元</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="market"
          label="销售价格/折扣比例"
      >
        <template #default="{ row }">
          <span v-if="row.type == 1">{{ row.market }}元</span>
          <span v-else>{{ row.market }}%</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="details"
          label="套餐说明">
      </el-table-column>
      <!--      <el-table-column
                align="center"
                prop="state"
                label="状态"
            >
              <template #default="{row}">
                <el-tooltip
                    :content="row.state == 1 ? '启用' : '暂未启用'"
                    :enterable="false"
                    placement="top"
                >
                  <el-switch
                      v-model="row.state"
                      active-color="#13ce66"
                      :active-value="1"
                      inactive-color="#ff4949"
                      :inactive-value="0"
                  />
                </el-tooltip>
              </template>
            </el-table-column>-->
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        align="center"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </div>
  <!--  </el-card>-->
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiDeleteTopPackage, apiGetTopPackageList, apiSetTopPackage} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      form: {
        type: '0',
      },
      activeName: '1',
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
      },
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    setTopPackage(e) {
      apiSetTopPackage({type: e}).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    rechargePackageSettings() {
      this.$router.push({
        path: 'rechargePackage/awardList',
      })
    },
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'rechargePackage/revise',
          query: {
            id: row.id,
          },
        })
      } else {
        this.$router.push({
          path: 'rechargePackage/revise',
          query: {
            id: '',
          }
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteTopPackage({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetTopPackageList(this.queryForm).then(res => {
        if (res.code = 200) {
          this.list = res.data.list
          this.count = res.data.count
          this.form.type = res.data.conf.value
        }
      })
      this.listLoading = false
    },
  }
}

</script>
<style>
.commodity_pic {
  width: 60px;
  height: 60px;
}
</style>
